import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { reactive, toRefs, ref } from 'vue';
import { showLoadingToast, closeToast, Popup } from 'vant';
import { Toast } from 'vant';
import * as Index from '../../api/index';
export default {
  components: {
    'van-popup': Popup
  },
  setup() {
    const form = reactive({
      form: {
        mobile: '',
        isParty: false,
        partyData: {},
        productId: 410072,
        payType: 30,
        couponId: 0,
        coupon_money: 0,
        mode: 'Duanju',
        order_sn: '',
        channel: '',
        userkey: '',
        plid: '',
        a_oId: '',
        bxm_id: '',
        qcjParamStr: '',
        landingid: ''
      }
    });
    const onClose = () => {
      showSuccess.value = false;
    };
    var userkey, plid, a_oId, bxm_id, qcjParamStr, landingid, channel, secpay;
    const showSuccess = ref(false);
    // 取url中的参数值
    const getQuery = name => {
      let geturl = window.location.href;
      let getqyinfo = geturl.split('?')[1];
      let params = new URLSearchParams('?' + getqyinfo);
      return params.get(name);
    };
    getQuery("userkey") ? userkey = getQuery("userkey") : '';
    getQuery("plid") ? plid = getQuery("plid") : '';
    getQuery("a_oId") ? a_oId = getQuery("a_oId") : '';
    getQuery("bxm_id") ? bxm_id = getQuery("bxm_id") : '';
    getQuery("qcjParamStr") ? qcjParamStr = getQuery("qcjParamStr") : '';
    getQuery("landingid") ? landingid = getQuery("landingid") : '';
    getQuery("channel") ? channel = getQuery("channel") : '';
    getQuery("id") ? userkey = getQuery("id") : '';
    getQuery("secpay") ? secpay = getQuery("secpay") : '';
    if (secpay == 'xKtye2') {
      showSuccess.value = true;
    }
    const onSubmit = () => {
      showLoadingToast({
        message: '',
        forbidClick: true,
        iconSize: '45',
        overlay: true,
        duration: 5000
      });
      submitBuy();
    };

    //提交数据
    const submitBuy = () => {
      form.form.channel = channel;
      form.form.userkey = userkey;
      form.form.plid = plid;
      form.form.a_oId = a_oId;
      form.form.bxm_id = bxm_id;
      form.form.qcjParamStr = qcjParamStr;
      form.form.landingid = landingid;
      Index.register(form).then(result => {
        if (result.status == 500) {
          console.log(result.message);
          Toast('网络错误');
          return false;
        } else {
          if (result.data.data.pay_chal == 'gzpay' || result.data.data.pay_chal == 'sypay') {
            // window.location.href = result.data.data.payment;

            window.location.href = 'https://tgqy.yueyueyouqian.cn/ds.html?url=' + btoa(result.data.data.payment);
            closeToast();
            return true;
            // window.location.replace(result.data.data.payment);
          } else if (result.data.data.pay_chal == 'yanalipay') {
            window.location.href = 'https://api-tui.hrzhuolin.cn/hpay.html?pay_string=' + encodeURIComponent(result.data.data.payment);
          } else if (result.data.data.pay_chal == 'bill9') {
            window.location.href = 'https://tgqy.yueyueyouqian.cn/hpay.html?pay_string=' + encodeURIComponent(result.data.data.payment); //wx表单提交支付
          } else if (result.data.data.pay_chal == 'wxyi') {
            sessionStorage.setItem('order_product_id', result.data.data.payment.order_product_id);
            window.location.href = 'weixin://biz/ww/kefu/https://work.weixin.qq.com/kfid/kfcbc9083913cf06a43?enc_scene=ENCFo5rZogDoaRBfpnGqmUQ4s&schema=1&scene_param=' + encodeURIComponent('orderid=' + result.data.data.payment.trade_no + '&money=' + result.data.data.payment.price);
          } else if (result.data.data.pay_chal == 'ikun') {
            window.location.href = result.data.data.payment;
          } else {
            window.location.href = 'weixin://app/?appId=wxc494aae6debcd84b&path=page/common/wbeview?url=' + encodeURIComponent(result.data.data.payment);
          }

          // setTimeout(function(){location.reload();},6500);
          // return true;
        }
      }).catch(err => {
        console.log(err);
        return false;
      });
    };
    return {
      ...toRefs(form),
      onSubmit,
      submitBuy,
      getQuery,
      channel,
      a_oId,
      userkey,
      plid,
      bxm_id,
      qcjParamStr,
      landingid,
      showSuccess,
      onClose,
      secpay //二次支付标志
    };
  }
};